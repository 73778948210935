import React, {useEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link, useLocation} from "@reach/router";
import Navigation from "../components/navigation";
import HeaderTop from "../components/headerTop";
import {Carousel} from "react-responsive-carousel";
import UseHeading from "../img/use-heading.png";
import BarcodePerspective from "../img/barcode-perspective.png";
import Barcode5 from "../img/bar-codes-5.jpg";
import FooterList from "../components/footerList";
import he from "he";
import classnames from "classnames";
import clientConfig from "../client-config";
import axios from "axios";
import _ from "lodash"

const DrawHtml = ({html}) => (
    <div className="large"
         dangerouslySetInnerHTML={{__html: "<div>" + html + "</div>"}}
    />
);

export default () => {
  const [loading, setLoading] = useState(false);
  const [posts2, setPosts2] = useState([]);
  const [news2, setNews2] = useState([]);
  const [events2, setEvents2] = useState([]);
  const [mainItem, setMainItem] = useState({});
  const [error, setError] = useState("");
  //const posts = data.allWordpressPost.edges
  const location = useLocation();
  //const {currentPage, numPages, cat, title} = pageContext
  //const currentPage = 0;
  //const numPages = 1;
  const wordPressSiteURL = clientConfig.siteUrl;

  const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/carousel?content=false&per_page=10`;
  const urlNews = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/news?content=false&media=false&per_page=2`;
  const urlEvents = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/events?content=false&media=false&per_page=2`;
  const urlMain = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/main?content=false&per_page=1`;
  const url2 = `${wordPressSiteURL}/wp-json/wp/v2/posts`
  useEffect(() => {
    setLoading(true);
    axios.get(url)
        .then(res => {
          if (res.data.length) {
            setLoading(false);
            setPosts2(res.data)
          } else {
            setLoading(false);
            setError("No news found");
          }
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });


    axios.get(urlNews)
        .then(res => {
          if (res.data.length) {
            setLoading(false);
            setNews2(res.data)
          } else {
            setLoading(false);
            setError("No news found");
          }
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });

    axios.get(urlEvents)
        .then(res => {
          if (res.data.length) {
            setLoading(false);
            setEvents2(res.data)
          } else {
            setLoading(false);
            setError("No events found");
          }
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });

    axios.get(urlMain)
        .then(res => {
          if (res.data.length) {
            setLoading(false);
            setMainItem(res.data[0])
          } else {
            setLoading(false);
            setError("No main found");
          }
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });

  }, []);

  const createMarkup = (data) => ({
    __html: data
  });

  // const posts2 = data.carousel.edges
  // const news2 = data.news.edges
  // const events2 = data.events.edges

//  const mainItem = data.main.edges[0].node;


  const regex = /(<([^>]+)>)/ig;

  return (

      <div className="toolkit">
        <header>
          <HeaderTop/>
          <Navigation apageprop={location.pathname}/>
        </header>

        <div className="full-width" style={{height: "400px"}}>
          {posts2.length ? (
          <Carousel
              infiniteLoop={true}
              autoPlay={true}
              useKeyboardArrows={true}
              showArrows={true}
              interval={3500}
              transitionTime={500}
              centerSlidePercentage={100}
              showThumbs={false}
          >

            {posts2.map((node, index) => {
              const title = node.title || node.slug
              let btnClass = 'item active';
              btnClass += " " + index;
              if (index === 0 || index % 5 === 0) {
                btnClass += ' bg-purple';
              } else if (index === 1 || index % 5 === 1) {
                btnClass += ' bg-lime';
              } else if (index === 2 || index % 5 === 2) {
                btnClass += ' bg-orange';
              } else if (index === 3 || index % 5 === 3) {
                btnClass += ' bg-sky';
              } else if (index === 4 || index % 5 === 4) {
                btnClass += ' bg-grass';
              }
              return (
                  <div key={node.slug} className="carousel-inner" style={{textAlign: "left"}}>
                    <div className={btnClass}>
                      <div className="container">
                        {node.media &&
                        <img className="pull-left"
                             style={{
                               width: "300px",
                               minHeight: "200px",
                               marginLeft: "60px",
                               objectFit: "cover",
                               margin: "0 30px 30px 0"
                             }}
                             src={node.media.medium}/>
                        }
                        <h1>
                          {he.decode(title)}
                        </h1>
                        <DrawHtml html={node.excerpt}/>

                        <Link to={"/news/news/p/" + decodeURIComponent(`${node.slug}/`)}
                              className="btn btn-primary bg-blue"
                        >
                          Дэлгэрэнгүй
                        </Link>

                      </div>

                    </div>
                  </div>
              );
            })}
          </Carousel>
          ):''}
        </div>


        <div className="bg-light-gray">
          <div className="container">
            <section>
              <div className="row">

                <div className="col-md-8">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="content-module-container">
                        <div className="content-module-wrapper top-border-peach">
                          {!_.isEmpty(mainItem) &&
                          <div className="content-module content-module-featured-news bg-blue">
                            {mainItem.media &&
                            <img style={{
                              maxHeight: "300px",
                              maxWidth: "800px",
                              objectFit: "cover"
                            }}
                                 src={mainItem.media.medium}
                                 alt="main"/>
                            }
                            <div>
                              <h4>
                                <Link to={"/news/news/p/" + decodeURIComponent(`${mainItem.slug}/`)}>
                                  {he.decode(mainItem.title)}
                                </Link>
                              </h4>

                              <p className="color-white" style={{textAlign: "justify", textJustify: "inter-word"}}
                                 dangerouslySetInnerHTML={{__html: mainItem.excerpt.replace(regex, '')}}></p>
                              <p><Link className="color-white-hover"
                                       to={"/news/news/p/" + decodeURIComponent(`${mainItem.slug}/`)}>Цааш нь &gt;</Link></p>

                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-6">
                      <div className="content-module-container">
                        <div className="content-module-wrapper top-border-orange">
                          <div className="content-module content-module-featured-news bg-blue">
                            <img src={BarcodePerspective} alt="Barcode"/>
                            <div>
                              <h4>
                                <a href="#0">Хяналтын оронг тооцох</a>
                              </h4>
                              <p className="color-white"><a
                                  href="https://www.gs1.org/services/check-digit-calculator"
                                  className="color-white-hover">
                                Check digit calculator.</a></p>

                              <Link
                                  to="https://www.gs1.org/services/check-digit-calculator"
                                  className="btn-secondary" role="button"
                              >
                                Хяналтын оронг тооцох тухай
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content-module-container ">
                        <div className="content-module-wrapper top-border-mint">
                          <div className="content-module content-module-padding">
                            <h4>
                              <Link to="/standards/glossary">GS1-ын нэр томьёоны тайлбар ба тодорхойлолт.</Link>
                            </h4>
                            <p className="small color-light-medium-gray">Glossary &#8226;GS1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="content-module-container flag-new">
                        <div className="content-module-bar bg-lavender"></div>
                        <div className="content-module-wrapper top-border-lavender">
                          <div className="content-module content-module-padding">
                            <h4>Мэдээ / Мэдээлэл</h4>
                            <ul>

                              {news2.map((node, index) => {
                                const title = node.title || node.slug;
                                let desc =  node.excerpt.replace(regex, ''); //stripHtml(node.excerpt);
                                desc = desc.replace(/(.{30})..+/, "$1…");
                                return (
                                    <li key={node.slug}>
                                      <Link to={"/news/news/p/" + decodeURIComponent(`${node.slug}/`)}>
                                        {he.decode(title)}
                                      </Link>
                                      <br/>
                                      <div dangerouslySetInnerHTML={{__html:desc}} />
                                    </li>
                                );
                              })}
                              <hr/>

                              <Link to="/news">Бусад мэдээ мэдээллийг харах &gt;</Link>

                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="content-module-container">
                        <div className="content-module-wrapper top-border-gold">
                          <div className="content-module content-module-padding clearfix">
                            <h4>Арга Хэмжээ</h4>

                            {events2.map((node, index) => {
                              const title = node.title || node.slug;
                              const dt = new Date(node.date);
                              const tt =  typeof(dt)
                              //const dt2 = Date.parse(node.date);
                              const year = dt.getUTCFullYear();
                              const m = dt.getUTCMonth() + 1;
                              const d = dt.getUTCDate();
                              return (
                                  <dl key={node.slug} className="clearfix">
                                    <dt><span>{m} - {d}</span>{year}</dt>
                                    <dd>
                                      <Link
                                          to={"/news/events/p/"+decodeURIComponent(`${node.slug}/`)}>
                                        {he.decode(title)}
                                      </Link>

                                    </dd>
                                  </dl>
                              );
                            })}

                            <hr/>

                            <Link to="/events">Бусад арга хэмжээг харах &gt;</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-md-4">
                  <div className="content-module-container">
                    <div className="content-module-wrapper top-border-teal">
                      <div className="content-module content-module-featured-news">
                        <img className="img-responsive" src={Barcode5} alt="Barcodes"/>
                        <div>
                          <h4>
                            <a className="color-blue" href="#0">Үйлчилгээ</a>
                          </h4>

                          <ul>
                            <li>
                              <Link to="/srv/barcode_get">Зураасан код авах</Link>
                            </li>
                            <li>
                              <Link to="/srv/barcode_print">Зураасан код хэвлэлт</Link>
                            </li>
                            <li>
                              <Link to="/service/brochure">Ном гарын авлага</Link>
                            </li>
                          </ul>

                          <a className="btn-primary" role="button"
                             href="http://app.gs1mongolia.org/company-self-create">Шинээр элсэх</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-module-container">
                    <div className="content-module-wrapper top-border-tangerine">
                      <div className="content-module content-module-featured-news">
                        <img className="img-responsive" src={UseHeading} alt="Use"/>
                        <div>
                          <h4>
                            <a className="color-blue" href="#0">Barcodes</a>
                          </h4>

                          <ul>
                            <li><Link to="/articles/1-dim">Нэг хэмжээст зураасан
                              кодны тухай</Link></li>
                            <li><Link to="/articles/2-dimensional-barcodes">Хоёр хэмжээст цэгэн
                              код</Link></li>
                            <li><Link to="/articles/rfid">Бүтээгдэхүүний
                              электрон код</Link></li>
                          </ul>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-module-container">
                    <div className="content-module-wrapper top-border-sky">
                      <div className="content-module content-module-padding">
                        <h4>Асуулт/Хариулт</h4>
                        <ul>
                          <li><Link to="/asuult/gs1-систем">
                            GS1 систем
                          </Link></li>
                          <li><Link to="/asuult/ecom-гэж-юу-вэ">
                            eCom гэж юу вэ ?
                          </Link></li>
                          <li><Link to="/asuult/epc-rfid">
                            EPC & RFID
                          </Link></li>
                          <li><Link to="/asuult/тээвэр-логистик">
                            Тээвэр логистик
                          </Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </section>
          </div>
        </div>

        <FooterList/>

      </div>
  )
}
