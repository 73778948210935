import React, {useEffect, useState} from "react";
import Layout from "../components/layout";
import he from "he";
import {Link, useLocation} from "@reach/router";
import axios from "axios";
import clientConfig from "../client-config";
import Loader from "../images/loading.gif";
import dayjs from "dayjs";

const util = require('util')


export default ({page}) => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [isLast, setIsLast] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState("newsletter");
    const [nextPage, setNextPage] = useState("newsletter");

    // const posts = data.allWordpressPost.edges
    //
    // const {currentPage, numPages, cat, title} = pageContext
    // const isFirst = currentPage === 1
    // const isLast = currentPage === numPages
    // const prevPage = currentPage - 1 === 1 ? `${cat}/` : `${cat}/` + (currentPage - 1).toString()
    // const nextPage = `${cat}/` + (currentPage + 1).toString()

    const location = useLocation();
    let p = 1;
    if (page !== undefined) {
        p = parseInt(page);
    }
    const wordPressSiteURL = clientConfig.siteUrl;
    const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/newsletter?content=false&media=false&page=${p}`;
    useEffect(() => {
        setLoading(true);
        axios.get(url)
            .then(res => {
                if (res.data.length) {
                    setLoading(false);
                    setPosts(res.data)
                    let tp = parseInt(res.headers["x-wp-totalpages"], 10);
                    let t = parseInt(res.headers["x-wp-total"], 10);
                    setTotalPages(tp);
                    setTotal(t);
                    setIsFirst(p === 1);
                    setIsLast(p === tp);
                    setPrevPage(p - 1 === 1 ? `/newsletter` : `/newsletter/` + (p - 1).toString());
                    setNextPage(`/newsletter/` + (p + 1).toString());
                    if (page !== undefined) {
                        setCurrentPage(parseInt(page));
                    }

                } else {
                    setLoading(false);
                    setError("No newsletter found");
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });

    }, [p]);

    const createMarkup = (data) => ({
        __html: data
    });

    return (
        <Layout aPageProp={location.pathname}>
            <br/><br/>
            <p style={{textAlign: "center"}}>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/gs1web.appspot.com/o/newsletter%2FSupply_chain_banner_new%20(1).jpg?alt=media&token=fbaad8d6-4e7d-4c3e-816d-2bf8abb93b65"/>
            </p>
            <p style={{marginTop: "20px", textAlign: "justify"}}>
                "GS1 Mongolia”-оос бизнес эрхлэгч гишүүн байгууллагууддаа зориулан хуваарилалт/нийлүүлэлтийн гинжин
                сувгийн үйл ажиллагааг хэрхэн удирдаж логистикийн менежментийг боловсронгуй болгох, гадаад, дотоод
                худалдааг хөнгөвчлөх түүнчлэн GS1 систем буюу зураасан кодны талаар илүү дэлгэрэнгүй ойлголт өгөх
                үүднээс тус системийн хэрэглэгдэх хүрээ, ач холбогдол болон бусад гишүүн орнуудад шинээр нэвтрүүлж
                байгаа санал санаачлага, шинэ туршилт, туршлагуудын талаарх мэдээ, мэдээллийг бэлтгэн улирал тутам
                энэхүү <strong>“Supply chain”</strong> цахим сониноороо дамжуулан хүргэдэг билээ.
            </p>
            <p style={{marginBottom: "20px", textAlign: "justify"}}>
                Тэгвэл эдгээр мэдээллийн талаар илүү ихийг мэдэж өөрсдийн үйл ажиллагаандаа хэрэгжүүлэн дэлхийн хэмжээнд
                хүлээн зөвшөөрөгдсөн байгууллага, хамт олон болохыг хүсвэл бидэнтэй байнга холбоо, харилцаатай байж
                хамтран ажиллахыг урьж байна.
            </p>
            {error && <div className="alert alert-danger" dangerouslySetInnerHTML={createMarkup(error)}/>}
            {posts.length ? (
                <div>

                    <table className="table table-bordered table-striped  table-dark">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Мэдээллийн гарчиг</th>
                            <th style={{width: "15%"}}>Огноо</th>
                        </tr>
                        </thead>
                        <tbody>
                        {posts.map((node, index) => {
                            const title = node.title || node.slug
                            let acf = node.acf
                            return (
                                <tr key={node.slug}>
                                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                                    <td>
                                        {acf &&
                                        <a style={{boxShadow: 'none'}} download href={node.acf.attach}>
                                            {he.decode(title)}
                                        </a>
                                        }
                                        {!acf &&
                                        he.decode(title)
                                        }
                                    </td>
                                    <td>{dayjs(node.date).format("YYYY-MM-DD")}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <ul
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            listStyle: 'none',
                            padding: 0,
                            paddingBottom: "25px"
                        }}
                    >
                        {!isFirst && (
                            <Link to={prevPage} rel="prev">
                                Өмнөх
                            </Link>
                        )}
                        {Array.from({length: totalPages}, (_, i) => (
                            <li
                                key={`pagination-number${i + 1}`}
                                style={{
                                    margin: 0,
                                }}
                            >
                                <Link
                                    to={`/newsletter/${i === 0 ? '' : i + 1}`}
                                    style={{
                                        padding: '10px',
                                        textDecoration: 'none',
                                        color: i + 1 === currentPage ? '#ffffff' : '',
                                        background: i + 1 === currentPage ? '#007acc' : '',
                                    }}
                                >
                                    {i + 1}
                                </Link>
                            </li>
                        ))}
                        {!isLast && (
                            <Link to={nextPage} rel="next">
                                Дараах
                            </Link>
                        )}
                    </ul>
                </div>
            ) : ''}
            {loading && <img className="loader" src={Loader} alt="Loader"/>}
        </Layout>
    )
}
