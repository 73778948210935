const assetsObject = {
    aboutus: require('./markdown-pages/about/aboutus.md'),
    history: require('./markdown-pages/about/history.md'),
    standards: require('./markdown-pages/standards/index.md'),
    _10steps1: require('./markdown-pages/standards/10steps1.md'),
    _10steps2: require('./markdown-pages/standards/10steps2.md'),
    color: require('./markdown-pages/standards/color.md'),
    laws: require('./markdown-pages/standards/laws.md'),
    barcode_get: require('./markdown-pages/service/Зураасан код авах.md'),
    barcode_print: require('./markdown-pages/service/Зураасан код хэвлэлт.md'),
    rfid: require('./markdown-pages/articles/Бүтээгдэхүүний электрон код.md')
}
module.exports = assetsObject