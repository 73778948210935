import React, {lazy, Suspense} from 'react'
import {Router} from "@reach/router";
import Main from "./pages/Main";
import Location from "./pages/location";
import Contact from "./pages/contact";
import News from "./pages/news";
import Events from "./pages/events";
import Post from "./pages/post";
import PostMd from "./pages/postmd";
import Keys from "./pages/standards/keys";
import Types from "./pages/standards/types";
import Questions from "./pages/questions";
import QuestionsIndex from "./pages/asuult/asuult";
import Brochures from "./pages/services/brochures";
import Newsletter from "./pages/newsletter";
import Countries from "./pages/standards/countries";
import Glossary from "./pages/standards/glossary";
import Identifier from "./pages/standards/identifier";
import Fee from "./pages/membership/fee";
import TwoDimBarcode from "./pages/articles/2-dimensional-barcodes";
import OneDimBarcode from "./pages/articles/1-dim";
import Gepir from "./pages/services/gepir";
import ReactGA from 'react-ga';


ReactGA.initialize('UA-138588125-3');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Main path="/"/>
                <Location path="/location"/>
                <Contact path="/contact"/>

                {/*about*/}
                <PostMd path="/ab/:localPath"/>
                <Post path="/about/:slug"/>

                {/*standards*/}
                <Keys path="/standards/keys"/>
                <Countries path="/standards/countries"/>
                <Glossary path="/standards/glossary"/>
                <Identifier path="/standards/identifier"/>
                <Types path="/standards/types"/>
                <PostMd path="/standards/:localPath"/>

                {/*service*/}
                <Gepir path="/service/gepir"/>
                <PostMd path="/srv/:localPath"/>
                <Brochures path="/service/brochure/:page"/>
                <Brochures path="/service/brochure"/>

                <Fee path="/membership/fee"/>

                {/*news*/}
                <Post path="/news/news/p/:slug"/>
                <News path="/news/:page"/>
                <News path="/news"/>

                <Post path="/news/events/p/:slug"/>
                <Events path="/events/:page"/>
                <Events path="/events"/>

                {/*newsletter*/}
                <Newsletter path="/newsletter/:page"/>
                <Newsletter path="/newsletter"/>

                {/*asuult*/}
                <QuestionsIndex path="/asuult"/>
                <Questions path="/asuult/:asuult"/>
                <Post path="/q/:slug"/>


                <OneDimBarcode path="/articles/1-dim"/>
                <TwoDimBarcode path="/articles/2-dimensional-barcodes"/>
                <PostMd path="/articles/:localPath"/>


            </Router>
        </Suspense>
    );

}
//
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
