import React, {useEffect, useState} from "react";
import Layout from "../../components/layout.js";
import LinkContainer from "../../components/LinkContainer.js";
import {useLocation} from "@reach/router";
import axios from "axios";
import clientConfig from "../../client-config";
import Loader from "../../images/loading.gif";

export default ({data}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [epc, setEpc] = useState(0);
    const [epcLoading, setEpcLoading] = useState(false);
    const [faq, setFaq] = useState(0);
    const [faqLoading, setFaqLoading] = useState(false);
    const [ecom, setEcom] = useState(0);
    const [ecomLoading, setEcomLoading] = useState(false);
    const [gdsn, setGdsn] = useState(0);
    const [gdsnLoading, setGdsnLoading] = useState(false);
    const [system, setSystem] = useState(0);
    const [systemLoading, setSystemLoading] = useState(false);
    const [gepir, setGepir] = useState(0);
    const [gepirLoading, setGepirLoading] = useState(false);
    const [cloud, setCloud] = useState(0);
    const [cloudLoading, setCloudLoading] = useState(false);
    const [mobile, setMobile] = useState(0);
    const [mobileLoading, setMobileLoading] = useState(false);
    const [standard, setStandard] = useState(0);
    const [standardLoading, setStandardLoading] = useState(false);
    const [print, setPrint] = useState(0);
    const [printLoading, setPrintLoading] = useState(false);
    const [transport, setTransport] = useState(0);
    const [transportLoading, setTransportLoading] = useState(false);
    const [health, setHealth] = useState(0);
    const [healthLoading, setHealthLoading] = useState(false);

    const getCount = (cat, cb) => {
        setLoading(true);
        const wordPressSiteURL = clientConfig.siteUrl;
        const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/${cat}?content=false&media=false&per_page=1`;
        axios.get(url)
            .then(res => {
                setLoading(false);
                let t = parseInt(res.headers["x-wp-total"], 10);
                cb(t);
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });
    }
    const getCount2 = (cat, cb, cbl) => {
        cbl(true);
        const wordPressSiteURL = clientConfig.siteUrl;
        const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/${cat}?content=false&media=false&per_page=1`;
        axios.get(url)
            .then(res => {
                cbl(false);
                let t = parseInt(res.headers["x-wp-total"], 10);
                cb(t);
            })
            .catch(err => {
                cbl(false);
                setError(err.message);
            });
    }
    useEffect(() => {
        getCount2("epc-rfid", setEpc, setEpcLoading);
        getCount2("faq", setFaq, setFaqLoading);
        getCount2("ecom", setEcom, setEcomLoading);
        getCount2("gdsn", setGdsn, setGdsnLoading);
        getCount2("gs1-system", setSystem, setSystemLoading);
        getCount2("gepir", setGepir, setGepirLoading);
        getCount2("gs1-cloud", setCloud, setCloudLoading);
        getCount2("mobile-commerce", setMobile, setMobileLoading);
        getCount2("global-standard", setStandard, setStandardLoading);
        getCount2("barcode-print", setPrint, setPrintLoading);
        getCount2("transport", setTransport, setTransportLoading);
        getCount2("health", setHealth, setHealthLoading);

    }, []);
    const location = useLocation();
    const createMarkup = (data) => ({
        __html: data
    });
    return (
        <Layout aPageProp={location.pathname}>
            <div>
                <h3> Асуулт / Хариулт</h3>
                {error && <div className="alert alert-danger" dangerouslySetInnerHTML={createMarkup(error)}/>}
                <ul>
                    <li><LinkContainer title={"Байнга тавигддаг асуултууд"}
                                       link={"/asuult/байнга-тавигддаг-асуултууд"}
                                       /> ( {faqLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/> : faq} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"GS1 систем"} link={"/asuult/gs1-систем"}
                                       /> ( {systemLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/> : system} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"eCom гэж юу вэ"} link={"/asuult/ecom-гэж-юу-вэ"}
                                       /> ( {ecomLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/> : ecom} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"EPC & RFID"} link={"/asuult/epc-rfid"}/> ( {epcLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/>: epc} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"Тээвэр логистик"} link={"/asuult/тээвэр-логистик"}
                                       /> ( {transportLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/> : transport} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"Эрүүл мэнд"} link={"/asuult/эрүүл-мэнд"}
                                       /> ( {healthLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/> : health} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"Mobile commerce"} link={"/asuult/mobile-commerce"}
                                       /> ( {mobileLoading ?
                    <img className="loader-inline" src={Loader} alt="Loader"/> : mobile} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"Глобал стандартын менежмент процесс"}
                                       link={"/asuult/глобал-стандартын-менежмент-процесс"}/>
                         ( {standardLoading ? <img className="loader-inline" src={Loader} alt="Loader"/> : standard} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"GDSN-МИГС"} link={"/asuult/gdsn-мигс"}/>
                        ( {gdsnLoading ? <img className="loader-inline" src={Loader} alt="Loader"/> : gdsn} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"GEPIR"} link={"/asuult/gepir"}/>
                         ( {gepirLoading ? <img className="loader-inline" src={Loader} alt="Loader"/> : gepir} Мэдээллүүд
                        )
                    </li>
                    <li><LinkContainer title={"GS1 Cloud гэж юу вэ?"} link={"/asuult/gs1-cloud-гэж-юу-вэ"}
                                       /> ( {cloudLoading ? <img className="loader-inline" src={Loader} alt="Loader"/> : cloud} Мэдээллүүд )
                    </li>
                    <li><LinkContainer title={"Зураасан кодыг хэрхэн хэвлэх вэ?"}
                                       link={"/asuult/зураасан-кодыг-хэрхэн-хэвлэх-вэ"}
                                       /> ( {printLoading ? <img className="loader-inline" src={Loader} alt="Loader"/> :print} Мэдээллүүд )
                    </li>
                </ul>

            </div>
        </Layout>
    )
}
