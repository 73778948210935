import React from "react";
import {Link} from "@reach/router";
import "../styles/wordpress.scss"
import juram from "../docs/barcode_juram_new.pdf"
import memberDoc from "../docs/barcode_member_application.pdf"
import productDoc from "../docs/barcode_product_application.pdf"

export default () => (
    <div style={{backgroundColor: "#ededed"}}>
        <div className="container">
            <footer>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4>Бидний Тухай</h4>
                        <ul>
                            <li>
                                <Link to="/ab/aboutus">Танилцуулга</Link>
                            </li>
                            <li>
                                <Link to="/about/greeting">Мэндчилгээ</Link>
                            </li>
                            <li>
                                <Link to="/ab/history">Он цагийн товчоон</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4>Үйлчилгээ</h4>
                        <ul>
                            <li>
                                <Link to="/srv/barcode_get">Зураасан код авах</Link>
                            </li>
                            <li>
                                <Link to="/srv/barcode_print">Зураасан код хэвлэлт</Link>
                            </li>
                            <li>
                                <Link to="/service/brochure">Ном гарын авлага</Link>
                            </li>
                            <li>
                                <Link
                                    to="/service/gepir">GEPIR</Link>
                            </li>
                            <li>
                                <a href="https://www.gs1.org/services/check-digit-calculator">Хяналтын оронг
                                    тооцох</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4>Мэдээ Мэдээлэл</h4>
                        <ul>
                            <li>
                                <a href="/news">Мэдээ, Мэдээлэл</a>
                            </li>
                            <li>
                                <a href="/events">Арга Хэмжээ</a>
                            </li>
                            <li>
                                <a href="/newsletter">Электрон сонин</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4 style={{fontSize: "18px"}}>Гишүүнчлэл</h4>
                        <ul>
                            <li>
                                <a href={juram}
                                   download>Журам</a>
{/*
                                <a href="https://firebasestorage.googleapis.com/v0/b/gs1web.appspot.com/o/colors%2Fbarcode_juram_new.pdf?alt=media&token=76805a69-c509-463a-b039-756eb7ea2835"
                                   download>Журам</a>
*/}
                            </li>
                            <li>
                                <a href={memberDoc}
                                   download>Байгууллагын анкет</a>
{/*

                                <a href="https://firebasestorage.googleapis.com/v0/b/gs1web.appspot.com/o/colors%2Fbarcode_member_application.pdf?alt=media&token=0e436d5a-8182-43ae-95d8-6652ea69b81f"
                                   download>Байгууллагын анкет</a>
*/}
                            </li>
                            <li>
                                <a href={productDoc}
                                   download>Бүтээгдэхүүний анкет</a>

{/*
                                <a href="https://firebasestorage.googleapis.com/v0/b/gs1web.appspot.com/o/colors%2Fbarcode_product_application.pdf?alt=media&token=6b556d57-e34e-4d0f-ada6-5ec9d3bce5bc"
                                   download>Бүтээгдэхүүний анкет</a>
*/}
                            </li>
                            <li>
                                <Link to="/membership/fee">Гишүүнчлэлийн төлбөр</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4 style={{fontSize: "18px"}}>Бусад</h4>
                        <ul>
                            <li><Link to="/asuult">Асуулт Хариулт</Link></li>
                            <li><Link to="/location">Холбоо барих</Link></li>
                            <li><Link to="/contact">Санал хүсэлт</Link></li>
                            <li><a href="https://gs1.org">English</a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-2">
<div className="social">
                            <h4>Холбоо барих:</h4>
                            <p><span className="primary">"GS1 Монгол"</span><br/>
                                "Морьтон" цогцолбор, 9 давхар,
                                19-р хороолол, 3-р хороо, ХУД,
                                УБ хот, Монгол улс{" "}<br/>
                                <span className="primary">Утас:</span> +976-77 000 865. <span className="primary">Email:</span>{" "}
                                <a href="mailto: info@gs1mn.org"> info@gs1mn.org</a></p>
                            <hr/>
                            <a href="https://www.facebook.com/GS1Mongolia" className="icon icon-facebook"></a>
                            <a href="https://twitter.com/MongoliaGs1" className="icon icon-twitter"></a>
                            <a href="https://www.youtube.com/channel/UClFCV8tfts6DueiJzW7IqYQ"
                               className="icon icon-youtube"></a>
</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        &nbsp;© 2020 GS1 Монгол Нийгэмлэг. Зохиогчийн эрх хуулиар хамгаалагдсан.
                    </div>
                </div>
            </footer>
        </div>
    </div>
)

